@use 'variables' as *;

body {
  background-color: $color-background;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color-text;
  margin: 0;
  padding: 0;
}

.app {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}