@use '../variables' as *;

.corner-pluses {
  &__plus {
    $positionPercent: 5%;

    position: absolute;
    z-index: 10;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/src/img/plus.svg');
    opacity: 0;
    inset: 50% 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s, inset 0.3s;

    @media screen and (min-width: $breakpoint-md) {
      width: 40px;
      height: 40px;
    }

    &--top-left {
      inset: $positionPercent auto auto $positionPercent;
      opacity: 0.5;
      transform: none;
    }

    &--top-right {
      inset: $positionPercent $positionPercent auto auto;
      opacity: 0.5;
      transform: none;
    }

    &--bottom-left {
      inset: auto auto $positionPercent $positionPercent;
      opacity: 0.5;
      transform: none;
    }

    &--bottom-right {
      inset: auto $positionPercent $positionPercent auto;
      opacity: 0.5;
      transform: none;
    }
  }
}