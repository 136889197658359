@use '../variables' as *;
@use 'sass:math';

.socials {
  $item-size: 60px;
  $item-size-lg: 80px;
  $plus-size: 12px;
  $plus-size-lg: 14px;

  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: $plus-size;
    height: $plus-size;
    position: absolute;
    left: math.div(-$plus-size, 2);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/src/img/plus.svg');

    @media screen and (min-width: $breakpoint-lg) {
      width: $plus-size-lg;
      height: $plus-size-lg;
      left: math.div(-$plus-size-lg, 2);
    }
  }

  &:before {
    top: math.div(-$plus-size, 2);

    @media screen and (min-width: $breakpoint-lg) {
      top: math.div(-$plus-size-lg, 2);
    }
  }

  &:after {
    bottom: math.div(-$plus-size, 2);

    @media screen and (min-width: $breakpoint-lg) {
      bottom: math.div(-$plus-size-lg, 2);
    }
  }

  &__item {
    $border: 1px solid rgba($white, 0.3);

    flex: none;
    width: $item-size;
    height: $item-size;
    border: $border;
    border-right: 0;
    position: relative;

    @media screen and (min-width: $breakpoint-lg) {
      width: $item-size-lg;
      height: $item-size-lg;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: $plus-size;
      height: $plus-size;
      position: absolute;
      right: math.div(-$plus-size, 2);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('/src/img/plus.svg');

      @media screen and (min-width: $breakpoint-lg) {
        width: $plus-size-lg;
        height: $plus-size-lg;
        right: math.div(-$plus-size-lg, 2);
      }
    }

    &:before {
      top: math.div(-$plus-size, 2);

      @media screen and (min-width: $breakpoint-lg) {
        top: math.div(-$plus-size-lg, 2);
      }
    }

    &:after {
      bottom: math.div(-$plus-size, 2);

      @media screen and (min-width: $breakpoint-lg) {
        bottom: math.div(-$plus-size-lg, 2);
      }
    }

    &:last-child {
      border-right: $border;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.3s;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-size: 30px;
      background-position: center;
      background-repeat: no-repeat;

      transform: translateY(-$item-size);
      filter: invert(0);
      transition: transform 0.3s, filter 0.3s;

      @media screen and (min-width: $breakpoint-lg) {
        background-size: 40px;
        transform: translateY(-$item-size-lg);
      }
    }

    &--tg::before,
    &--tg::after {
      background-image: url('/src/img/telegram.svg');
    }

    &--gh::before,
    &--gh::after {
      background-image: url('/src/img/github.svg');
    }

    &--in::before,
    &--in::after {
      background-image: url('/src/img/linkedin.svg');
    }

    &:hover {
      background-color: $white;

      &::before,
      &::after {
        transform: translateY(0);
        filter: invert(1);
      }
    }
  }
}