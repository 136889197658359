@import "https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@500&display=swap";
body {
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: JetBrains Mono, monospace;
  font-size: 16px;
  font-weight: 500;
}

.app {
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

.container {
  box-sizing: border-box;
  padding: 20px;
}

@media screen and (width >= 768px) {
  .container {
    padding: 40px;
  }
}

@media screen and (width >= 1200px) {
  .container {
    width: 1200px;
    margin: 0 auto;
  }
}

.opening {
  opacity: 0;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (width >= 768px) {
  .opening {
    font-size: 50px;
  }
}

.opening__roll {
  gap: 15px;
  display: flex;
  overflow: hidden;
}

.opening__roll-item {
  width: 20px;
  height: 40px;
}

@media screen and (width >= 768px) {
  .opening__roll-item {
    width: 40px;
    height: 40px;
  }
}

.opening__roll-item > * {
  width: 100%;
  height: 100%;
  line-height: 40px;
  display: block;
}

@media screen and (width >= 768px) {
  .opening__roll-item > * {
    line-height: 40px;
  }
}

.opening__plus {
  background-image: url("plus.733f37bd.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.content {
  text-transform: uppercase;
  opacity: 0;
}

.content__header {
  margin-bottom: 20px;
  font-size: 60px;
  line-height: 1.2;
}

@media screen and (width >= 768px) {
  .content__header {
    font-size: 80px;
  }
}

@media screen and (width >= 1200px) {
  .content__header {
    font-size: 120px;
  }
}

.content__description {
  margin-bottom: 25px;
  font-size: 28px;
}

@media screen and (width >= 768px) {
  .content__description {
    margin-bottom: 40px;
    font-size: 40px;
  }
}

@media screen and (width >= 1200px) {
  .content__description {
    margin-bottom: 50px;
    font-size: 50px;
  }
}

.content__main {
  z-index: 1;
  position: relative;
}

.content__scene {
  z-index: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.socials {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.socials:before, .socials:after {
  content: "";
  background-image: url("plus.733f37bd.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  left: -6px;
}

@media screen and (width >= 1200px) {
  .socials:before, .socials:after {
    width: 14px;
    height: 14px;
    left: -7px;
  }
}

.socials:before {
  top: -6px;
}

@media screen and (width >= 1200px) {
  .socials:before {
    top: -7px;
  }
}

.socials:after {
  bottom: -6px;
}

@media screen and (width >= 1200px) {
  .socials:after {
    bottom: -7px;
  }
}

.socials__item {
  border: 1px solid #ffffff4d;
  border-right: 0;
  flex: none;
  width: 60px;
  height: 60px;
  position: relative;
}

@media screen and (width >= 1200px) {
  .socials__item {
    width: 80px;
    height: 80px;
  }
}

.socials__item:before, .socials__item:after {
  content: "";
  background-image: url("plus.733f37bd.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  right: -6px;
}

@media screen and (width >= 1200px) {
  .socials__item:before, .socials__item:after {
    width: 14px;
    height: 14px;
    right: -7px;
  }
}

.socials__item:before {
  top: -6px;
}

@media screen and (width >= 1200px) {
  .socials__item:before {
    top: -7px;
  }
}

.socials__item:after {
  bottom: -6px;
}

@media screen and (width >= 1200px) {
  .socials__item:after {
    bottom: -7px;
  }
}

.socials__item:last-child {
  border-right: 1px solid #ffffff4d;
}

.socials__link {
  width: 100%;
  height: 100%;
  transition: background-color .3s;
  display: block;
  overflow: hidden;
}

.socials__link:before, .socials__link:after {
  content: "";
  filter: invert(0);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  width: 100%;
  height: 100%;
  transition: transform .3s, filter .3s;
  display: block;
  transform: translateY(-60px);
}

@media screen and (width >= 1200px) {
  .socials__link:before, .socials__link:after {
    background-size: 40px;
    transform: translateY(-80px);
  }
}

.socials__link--tg:before, .socials__link--tg:after {
  background-image: url("telegram.4f12a7b4.svg");
}

.socials__link--gh:before, .socials__link--gh:after {
  background-image: url("github.f3b658c6.svg");
}

.socials__link--in:before, .socials__link--in:after {
  background-image: url("linkedin.91710211.svg");
}

.socials__link:hover {
  background-color: #fff;
}

.socials__link:hover:before, .socials__link:hover:after {
  filter: invert();
  transform: translateY(0);
}

.corner-pluses__plus {
  z-index: 10;
  opacity: 0;
  background-image: url("plus.733f37bd.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  transition: opacity .3s, inset .3s;
  position: absolute;
  inset: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (width >= 768px) {
  .corner-pluses__plus {
    width: 40px;
    height: 40px;
  }
}

.corner-pluses__plus--top-left {
  opacity: .5;
  inset: 5% auto auto 5%;
  transform: none;
}

.corner-pluses__plus--top-right {
  opacity: .5;
  inset: 5% 5% auto auto;
  transform: none;
}

.corner-pluses__plus--bottom-left {
  opacity: .5;
  inset: auto auto 5% 5%;
  transform: none;
}

.corner-pluses__plus--bottom-right {
  opacity: .5;
  inset: auto 5% 5% auto;
  transform: none;
}
/*# sourceMappingURL=index.4058fda8.css.map */
