@use '../variables' as *;

.content {
  text-transform: uppercase;
  opacity: 0;

  &__header {
    font-size: 60px;
    line-height: 1.2;
    margin-bottom: 20px;

    @media screen and (min-width: $breakpoint-md) {
      font-size: 80px;
    }

    @media screen and (min-width: $breakpoint-lg) {
      font-size: 120px;
    }
  }

  &__description {
    font-size: 28px;
    margin-bottom: 25px;

    @media screen and (min-width: $breakpoint-md) {
      font-size: 40px;
      margin-bottom: 40px;
    }

    @media screen and (min-width: $breakpoint-lg) {
      font-size: 50px;
      margin-bottom: 50px;
    }
  }

  &__main {
    position: relative;
    z-index: 1;
  }

  &__scene {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}